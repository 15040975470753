export const INIT_SECTIONS = 'INIT_SECTIONS'
export const SHOW_FROM_STEP = 'SHOW_FROM_STEP'
export const SHOW_UP_TO_STEP = 'SHOW_UP_TO_STEP'
export const ANSWER_ACTIVITY_QUESTION = 'ANSWER_ACTIVITY_QUESTION'
export const SHOW_CONGRATULATIONS = 'SHOW_CONGRATULATIONS'
export const SHOW_RULERIZE = 'SHOW_RULERIZE'
export const SHOW_OUTRO = 'SHOW_OUTRO'
export const TOGGLE_STRATEGY = 'TOGGLE_STRATEGY'
export const POST_UPDATE_USER_STRATEGIES_REQUEST = 'POST_UPDATE_USER_STRATEGIES_REQUEST'
export const POST_UPDATE_USER_STRATEGIES_REQUEST_SUCCESS =
  'POST_UPDATE_USER_STRATEGIES_REQUEST_SUCCESS'
export const POST_UPDATE_USER_STRATEGIES_REQUEST_FAILURE =
  'POST_UPDATE_USER_STRATEGIES_REQUEST_FAILURE'

export function initSections(sections) {
  return {
    type: INIT_SECTIONS,
    payload: {
      sections,
    },
  }
}

export function showFromStep(id) {
  return {
    type: SHOW_FROM_STEP,
    payload: {
      id,
    },
  }
}

export function showUpToStep(id) {
  return {
    type: SHOW_UP_TO_STEP,
    payload: {
      id,
    },
  }
}

export function answerQuestion(question, answer) {
  return {
    type: ANSWER_ACTIVITY_QUESTION,
    payload: {
      question,
      answer,
    },
  }
}

export function showCongratulations() {
  return {
    type: SHOW_CONGRATULATIONS,
  }
}

export function showRulerize() {
  return {
    type: SHOW_RULERIZE,
  }
}

export function showOutro() {
  return {
    type: SHOW_OUTRO,
  }
}

export function toggleStrategy(id) {
  return {
    type: TOGGLE_STRATEGY,
    payload: {
      id,
    },
  }
}

export function requestUpdateUserStrategies(signUpIds, signOutIds) {
  return {
    type: POST_UPDATE_USER_STRATEGIES_REQUEST,
    payload: {
      signUpIds,
      signOutIds,
    },
  }
}

export function requestUpdateUserStrategiesSuccess(responses) {
  return {
    type: POST_UPDATE_USER_STRATEGIES_REQUEST_SUCCESS,
    payload: {
      responses,
    },
  }
}

export function requestUpdateUserStrategiesFailure(error) {
  return {
    type: POST_UPDATE_USER_STRATEGIES_REQUEST_FAILURE,
    payload: {
      error,
    },
  }
}
