import _ from 'lodash'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { getJWT } from 'common/utils'
import { getApiUrl } from 'services/selectors'
import { requests } from 'common/requests'
import { httpRequest } from 'services/saga'

import {
  ANSWER_ACTIVITY_QUESTION,
  POST_UPDATE_USER_STRATEGIES_REQUEST,
  requestUpdateUserStrategiesFailure,
  requestUpdateUserStrategiesSuccess,
} from './actions'

import { postActivityResponse } from './index'

export function* answerQuestionSaga({ payload }) {
  const answers = yield select((state) => state.module.progress.answers)
  const user = yield select((state) => state.root.user)
  const answersByAactivity = _.groupBy(answers, 'activityId')
  const { activityId } = answers[payload.question.id]
  const answersForActivity = answersByAactivity[activityId]

  if (_.every(answersForActivity, 'answered')) {
    yield put(
      postActivityResponse.actions.request({
        data: {
          person_id: user.id,
          activity_id: activityId,
          response: {
            answers: _.map(_.orderBy(answersForActivity, ['indexInActivity', 'asc']), (answer) => ({
              question_id: answer.questionId,
              content: answer.content,
            })),
          },
        },
      }),
    )
  }
}

export function* updateUserStrategiesSaga({ payload: { signUpIds, signOutIds } }) {
  const jwt = getJWT()
  const apiURL = yield select(getApiUrl)
  try {
    const responses = yield all(
      _.flattenDeep([
        _.map(signOutIds, (strategyId) =>
          call(httpRequest, {
            req: requests.post,
            url: `${apiURL}/rpc/strategy_sign_out`,
            data: { strategy_id: strategyId },
            headers: {
              Authorization: `Bearer ${jwt}`,
              Prefer: 'params=single-object',
            },
          }),
        ),
        _.map(signUpIds, (strategyId) =>
          call(httpRequest, {
            req: requests.post,
            url: `${apiURL}/rpc/strategy_sign_up`,
            data: { strategy_id: strategyId },
            headers: {
              Authorization: `Bearer ${jwt}`,
              Prefer: 'params=single-object',
            },
          }),
        ),
      ]),
    )
    yield put(requestUpdateUserStrategiesSuccess(responses))
  } catch (error) {
    yield put(requestUpdateUserStrategiesFailure(error))
  }
}

export default function* saga() {
  yield all([
    takeEvery(ANSWER_ACTIVITY_QUESTION, answerQuestionSaga),
    takeLatest(POST_UPDATE_USER_STRATEGIES_REQUEST, updateUserStrategiesSaga),
  ])
}
