import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  completeNudgeService,
  deleteSchoolNudgeSetService,
  getAllNudgeSetsService,
  getMyNudgeSetsService,
  getTopNudgeSetService,
  postSchoolNudgeSetService,
} from 'common/services/nudges'
import { changeModal } from 'services/actions'

import {
  COMPLETE_NUDGE,
  LOAD_ALL_NUDGE_SETS,
  LOAD_MY_NUDGE_SETS,
  PUBLISH_NUDGE,
  UNPUBLISH_NUDGE,
} from './actions'

function* loadAllNudgeSets() {
  yield call(getAllNudgeSetsService.requestSaga, {
    payload: {
      urlParams: {},
    },
  })

  // School Statistics
  yield call(getTopNudgeSetService.requestSaga, {
    payload: {
      urlParams: {},
    },
  })
}

function* loadMyNudgeSets() {
  yield call(getMyNudgeSetsService.requestSaga, {
    payload: {
      urlParams: {},
    },
  })
}

function* completeNudgeSaga({ payload: { id, isImplementationTeam } }) {
  yield call(completeNudgeService.requestSaga, {
    payload: {
      data: { nudge_id: id },
    },
  })

  yield isImplementationTeam ? loadAllNudgeSets() : loadMyNudgeSets()
}

function* publishNudgeSaga({ payload: { nudgeSetId, schoolId, personId } }) {
  yield call(postSchoolNudgeSetService.requestSaga, {
    payload: {
      data: {
        nudge_set_id: nudgeSetId,
        school_id: schoolId,
        person_id: personId,
      },
    },
  })

  yield loadAllNudgeSets()
  yield put(changeModal({ isOpen: false }))
}

function* unpublishNudgeSaga({ payload: { nudgeSetId, schoolId } }) {
  yield call(deleteSchoolNudgeSetService.requestSaga, {
    payload: {
      urlParams: {
        nudge_set_id: nudgeSetId,
        school_id: schoolId,
      },
    },
  })

  yield loadAllNudgeSets()
  yield put(changeModal({ isOpen: false }))
}

export default function* saga() {
  yield all([
    takeLatest(PUBLISH_NUDGE, publishNudgeSaga),
    takeLatest(UNPUBLISH_NUDGE, unpublishNudgeSaga),
    takeLatest(COMPLETE_NUDGE, completeNudgeSaga),
    takeLatest(LOAD_ALL_NUDGE_SETS, loadAllNudgeSets),
    takeLatest(LOAD_MY_NUDGE_SETS, loadMyNudgeSets),
  ])
}
